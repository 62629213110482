/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { Icon } from "@material-ui/core";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { UIActions, userRoles } from "../../../../../_component/constant";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();

  const { rouvrDefaultRole, permissions } = useSelector(({ auth }) => ({
    rouvrDefaultRole: auth.user ? auth.user.rouvrDefaultRole : null,
    permissions: auth.permissions,
  }));

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const menuItems = [
    {
      title: "Search Student",
      key: "STUDENT_DETAILS",
      url: "/student-details",
      icon: "dashboard",
    },
    {
      title: "Register Student",
      key: "FATHER_DETAILS",
      url: "/student-details/new",
      icon: "dashboard",
    },
    {
      title: "Online Registered",
      key: "MOTHER_DETAILS",
      url: "/online-registered",
      icon: "dashboard",
    },
    {
      title: "Online Transaction",
      key: "ONLINE_TRANSACTION",
      url: "/online-transaction",
      icon: "dashboard",
    },
  ];

  const menuItems2 = [
    {
      title: "Transfer Student",
      key: "TRANSFER_STUDENT",
      url: "/transfer-students",
      icon: "dashboard",
    },
    {
      title: "Transfer To",
      key: "TRANSFER_TO",
      url: "/transfer-to",
      icon: "dashboard",
    },
    {
      title: "Transfer From",
      key: "TRANSFER_FROM",
      url: "/transfer-from",
      icon: "dashboard",
    },
  ];

  const Others = [
    {
      title: "ID Card",
      key: "ID_CARD",
      url: "/student-id-card",
      icon: "dashboard",
    },
    {
      title: "Student Sibling List",
      key: "STUDENT_SIBLING_LIST",
      url: "/student-sibling-list",
      icon: "dashboard",
    },
    {
      title: "Student Update Request",
      key: "STUDENT_UPDATE_REQUEST",
      url: "/student-update-request",
      icon: "dashboard",
    },
    {
      title: "Login Detail Request",
      key: "LOGIN_DETAIL_REQUEST",
      url: "/login-detail-request",
      icon: "dashboard",
    },
    {
      title: "Mass Update",
      key: "MASS_UPDATE",
      url: "/mass-update",
      icon: "dashboard",
    },
    {
      title: "Promote Student",
      key: "PROMOTE_STUDENT",
      url: "/promote-students",
      icon: "dashboard",
    },
    {
      title: "Student Import",
      key: "STUDENT_IMPORT",
      url: "/student-imports",
      icon: "dashboard",
    },
    {
      title: "Board Registration Number",
      key: "BOARD_REGISTRATION_NUMBER",
      url: "/board-registration-numbers",
      icon: "dashboard",
    },
    {
      title: "Temparature Log",
      key: "TEMPERATURE_LOG",
      url: "/temperature-logs",
      icon: "dashboard",
    },
  ];

  const menuItems3 = [
    {
      title: "Transfer Certificate",
      key: "TRANSFER_CERTIFICATE",
      url: "/transfer-certificates/add",
      icon: "dashboard",
    },

    {
      title: "Income Tax Certificate",
      key: "INCOME_TAX_CERTIFICATE",
      url: "/income-tax-certificates",
      icon: "dashboard",
    },
    {
      title: "Student Health Card",
      key: "STUDENT_HEALTH_CARD",
      url: "/student-health-cards",
      icon: "dashboard",
    },
    {
      title: "Character Certificate",
      key: "CHARACTER_CERTIFICATE",
      url: "/character-certificates",
      icon: "dashboard",
    },
    {
      title: "Compalint Letter",
      key: "COMPALINT_LETTER",
      url: "/compalint-letters",
      icon: "dashboard",
    },
    {
      title: "Appretiation Certificate",
      key: "APPRETIATION_CERTIFICATE",
      url: "/appretiation-certificates",
      icon: "dashboard",
    },
    {
      title: "Permission Certificate",
      key: "PERMISSION_CERTIFICATE",
      url: "/permission-certificates",
      icon: "dashboard",
    },
    {
      title: "Enquiry Form",
      key: "ENQUIRY_FROM",
      url: "/enquiry-form",
      icon: "dashboard",
    },
  ];

  const Reports = [
    {
      title: "Student Status",
      key: "STUDENT_STATUS",
      url: "/student-status",
      icon: "dashboard",
    },
    {
      title: "Strength Comparision",
      key: "STRENGTH_COMPARISION",
      url: "/strength-comparision",
      icon: "dashboard",
    },
    {
      title: "TC Issued",
      key: "TC_ISSUED",
      url: "/tc-issued",
      icon: "dashboard",
    },
    {
      title: "Student Status Log",
      key: "STUDENT_STATUS_LOG",
      url: "/student-status-log",
      icon: "dashboard",
    },
    {
      title: "Student Optional Subject",
      key: "STUDENT_OPTIONAL_SUBJECT",
      url: "/student-optional-subject",
      icon: "dashboard",
    },
    {
      title: "Left Student",
      key: "LEFT_STUDENT",
      url: "/left-student",
      icon: "dashboard",
    },
    {
      title: "Login Status Report",
      key: "LOGIN_STATUS_REPORT",
      url: "/login-status-report",
      icon: "dashboard",
    },
    {
      title: "Vaccine And Covid Report",
      key: "VACCINE_COVID_REPORT",
      url: "/vaccine-report",
      icon: "dashboard",
    },
    {
      title: "Temperature Log Report",
      key: "TEMPERATURE_LOG_REPORT",
      url: "/temperature-log-report",
      icon: "dashboard",
    },
    {
      title: "Student Login Report",
      key: "STUDENT_LOGIN_REPORT",
      url: "/student-login-report",
      icon: "dashboard",
    },
  ];

 
  const AttendanceItem = [
    {
      title: "Daily",
      key: "Daily",
      url: "/daily",
      icon: "dashboard",
    },
  ];


  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className="menu-section card bg-primary pb-2">
          <h4 className="menu-text pt-4 justify-content-center text-white">Students</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <img src={toAbsoluteUrl("/media/svg/icons/General/Enrollement.png")} alt="Enrollement" />
            </span>
            <span className="menu-text">Enrollment</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="">
              {menuItems.map((menuItem) => {
                const key = `LIST_${menuItem.key}`;

                return (
                  (rouvrDefaultRole === userRoles.superAdmin ||
                    permissions.includes(UIActions[key])) && (
                    <li
                      key={menuItem.key}
                      className={`menu-item ${getMenuItemActive(
                        "/google-material/inputs"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to={menuItem.url}>
                        <span className="menu-text">{menuItem.title}</span>
                      </NavLink>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <img
                src={toAbsoluteUrl("/media/svg/icons/General/Transfer-new.png")} alt="Transfer-new"
              />
            </span>
            <span className="menu-text">Transfer</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="">
              {menuItems2.map((menuItem) => {
                const key = `LIST_${menuItem.key}`;

                return (
                  (rouvrDefaultRole === userRoles.superAdmin ||
                    permissions.includes(UIActions[key])) && (
                    <li
                      key={menuItem.key}
                      className={`menu-item ${getMenuItemActive(
                        "/google-material/inputs"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to={menuItem.url}>
                        <span className="menu-text">{menuItem.title}</span>
                      </NavLink>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <img
                src={toAbsoluteUrl("/media/svg/icons/General/Certificate.png")} alt="Certificate"
              />
            </span>
            <span className="menu-text">Certificate</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="">
              {menuItems3.map((menuItem) => {
                const key = `LIST_${menuItem.key}`;

                return (
                  (rouvrDefaultRole === userRoles.superAdmin ||
                    permissions.includes(UIActions[key])) && (
                    <li
                      key={menuItem.key}
                      className={`menu-item ${getMenuItemActive(
                        "/google-material/inputs"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to={menuItem.url}>
                        <span className="menu-text">{menuItem.title}</span>
                      </NavLink>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <img src={toAbsoluteUrl("/media/svg/icons/General/Others.png")} alt="Others"/>
            </span>
            <span className="menu-text">Others</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />

            <ul className="">
              {Others.map((menuItem) => {
                const key = `LIST_${menuItem.key}`;

                return (
                  (rouvrDefaultRole === userRoles.superAdmin ||
                    permissions.includes(UIActions[key])) && (
                    <li
                      key={menuItem.key}
                      className={`menu-item ${getMenuItemActive(
                        "/google-material/inputs"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to={menuItem.url}>
                        <span className="menu-text">{menuItem.title}</span>
                      </NavLink>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <img
                src={toAbsoluteUrl("/media/svg/icons/General/Reports.png")} alt="Reports"
              />
            </span>
            <span className="menu-text">Reports</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="">
              {Reports.map((menuItem) => {
                const key = `LIST_${menuItem.key}`;

                return (
                  (rouvrDefaultRole === userRoles.superAdmin ||
                    permissions.includes(UIActions[key])) && (
                    <li
                      key={menuItem.key}
                      className={`menu-item ${getMenuItemActive(
                        "/google-material/inputs"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to={menuItem.url}>
                        <span className="menu-text">{menuItem.title}</span>
                      </NavLink>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <img
                src={toAbsoluteUrl("/media/svg/icons/General/Attendence.png")} alt="Attendence"
              />
            </span>
            <span className="menu-text">Attendance</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {AttendanceItem.map((menuItem) => {
                const key = `LIST_${menuItem.key}`;

                return (
                  (rouvrDefaultRole === userRoles.superAdmin ||
                    permissions.includes(UIActions[key])) && (
                    <li
                      key={menuItem.key}
                      className={`menu-item ${getMenuItemActive(
                        menuItem.url,
                        false
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to={menuItem.url}>
                        <span className="svg-icon menu-icon">
                          <Icon>{menuItem.icon}</Icon>
                        </span>
                        <span className="menu-text">{menuItem.title}</span>
                      </NavLink>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        </li>
      </ul>
    </>
  );
}
