import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { userRoles } from "../../../../_component/constant";
import {
  getUserByToken,
  getUserPermissions,
  getInstitutes,
  getBranches,
  setInstituteBranch,
} from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  InstitutesRequested: "[Request Institutes] Action",
  InstitutesLoaded: "[Load Institutes] API",
  BranchesRequested: "[Request Branches] Action",
  BranchesLoaded: "[Load Branches] API",
  SetInstituteBranchRequested: "[Request Set Institute Branch] Action",
  SetInstituteBranchLoaded: "[Load Set Institute Branch] API",
  UserPermissionsRequested: "[Request User Permissions] Action",
  UserPermissionsLoaded: "[Load User Permissions] API",
};

const initialAuthState = {
  user: null,
  institutes: [],
  branches: [],
  branchName: null,
  userRoles: [],
  permissions: null,
};

export const reducer = persistReducer(
  {
    storage,
    key: "v726-demo1-auth",
    whitelist: [],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { accessToken, refreshToken, referralToken } = action.payload;

        localStorage.setItem(
          "authToken",
          JSON.stringify({ accessToken, refreshToken, referralToken })
        );

        return state;
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        localStorage.removeItem("authToken");
        return { ...state, ...initialAuthState };
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.InstitutesLoaded: {
        const { institutes } = action.payload;

        return { ...state, institutes };
      }

      case actionTypes.BranchesLoaded: {
        const { branches } = action.payload;
        return { ...state, branches };
      }

      case actionTypes.SetInstituteBranchLoaded: {
        const { data } = action.payload;

        if (data) {
          const { accessToken, refreshToken, referralToken, branchName } = data;

          localStorage.setItem(
            "authToken",
            JSON.stringify({ accessToken, refreshToken, referralToken })
          );

          return { ...state, branchName };
        } else {
          localStorage.removeItem("authToken");

          return { ...state, branchName: null };
        }
      }

      case actionTypes.UserPermissionsLoaded: {
        const { userRoles, permissions } = action.payload;

        return { ...state, userRoles, permissions };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (accessToken, refreshToken, referralToken) => ({
    type: actionTypes.Login,
    payload: { accessToken, refreshToken, referralToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  requestInstitutes: () => ({
    type: actionTypes.InstitutesRequested,
    payload: {},
  }),
  fulfillInstitutes: (institutes) => ({
    type: actionTypes.InstitutesLoaded,
    payload: { institutes },
  }),
  requestBranches: () => ({
    type: actionTypes.BranchesRequested,
    payload: {},
  }),
  fulfillBranches: (branches) => ({
    type: actionTypes.BranchesLoaded,
    payload: { branches },
  }),
  requestSetInstituteBranch: ({ instituteId, branchId, branchName }) => ({
    type: actionTypes.SetInstituteBranchRequested,
    payload: { instituteId, branchId, branchName },
  }),
  fulfillSetInstituteBranch: (data) => ({
    type: actionTypes.SetInstituteBranchLoaded,
    payload: { data },
  }),
  requestUserPermissions: () => ({
    type: actionTypes.UserPermissionsRequested,
    payload: {},
  }),
  fulfillUserPermissions: (userRoles, permissions) => ({
    type: actionTypes.UserPermissionsLoaded,
    payload: { userRoles, permissions },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    if (user && user.rouvrDefaultRole !== userRoles.superAdmin) {
      const { data: institutes } = yield getInstitutes();
      const { data: branches } = yield getBranches();

      yield put(actions.fulfillInstitutes(institutes));
      yield put(actions.fulfillBranches(branches));

      if (branches && branches.length > 0) {
        const { data } = yield setInstituteBranch({
          moduleName: process.env.REACT_APP_MODULE_NAME,
          instituteId: branches[0].instituteId,
          branchId: branches[0].id,
        });

        yield put(
          actions.fulfillSetInstituteBranch({
            ...data,
            branchName: branches[0].name,
          })
        );

        yield put(
          actions.requestBranches({
            ...data,
            branchName: branches[0].name,
          })
        );
      }

      yield put(actions.requestUserPermissions());
    }

    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(
    actionTypes.InstitutesRequested,
    function* institutesRequested() {
      const { data: institutes } = yield getInstitutes();

      yield put(actions.fulfillInstitutes(institutes));
    }
  );

  yield takeLatest(
    actionTypes.BranchesRequested,
    function* branchesRequested() {
      const { data: branches } = yield getBranches();

      yield put(actions.fulfillBranches(branches));
    }
  );

  yield takeLatest(
    actionTypes.SetInstituteBranchRequested,
    function* setInstituteBranchRequested({ payload }) {
      const { instituteId, branchId, branchName } = payload;

      const { data } = yield setInstituteBranch({
        moduleName: process.env.REACT_APP_MODULE_NAME,
        instituteId,
        branchId,
      });

      yield put(actions.fulfillSetInstituteBranch({ ...data, branchName }));
    }
  );

  yield takeLatest(
    actionTypes.UserPermissionsRequested,
    function* userPermissionsRequested() {
      const {
        data: { userRoles, permissions },
      } = yield getUserPermissions();

      yield put(actions.fulfillUserPermissions(userRoles, permissions));
    }
  );
}
