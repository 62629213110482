/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { forwardRef, Fragment } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_helpers";
import { actions as authActions } from "../../../../../app/modules/Auth/_redux/authRedux";
import { userRoles } from "../../../../../_component/constant";

const SwitchProfileDropdownToggle = forwardRef((props, ref) => {
  return (
    <a
      ref={ref}
      className="btn btn-clean border-dark btn-sm btn-icon"
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </a>
  );
});

const QuickActionsDropdownToggle = forwardRef((props, ref) => {
  return (
    <a
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
      id="kt_subheader_quick_actions"
      className={`btn btn-primary btn-fixed-height font-weight-bold px-2 px-lg-5 mr-2`}
    >
      <span className="svg-icon svg-icon-lg">
        <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
      </span>
      {` `}Switch Profile
    </a>
  );
});

export function TopActions() {
  const dispatch = useDispatch();

  const { rouvrDefaultRole, userRoles: roles, branchName } = useSelector(
    ({ auth }) => ({
      rouvrDefaultRole: auth.user ? auth.user.rouvrDefaultRole : null,
      userRoles: auth.userRoles,
      branchName: auth.branchName,
    })
  );

  return (
    <>
      {rouvrDefaultRole !== userRoles.superAdmin && (
        <>
          <p className="subheader-title text-dark font-weight-bold my-2 mr-3">
            {branchName}
          </p>
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              as={QuickActionsDropdownToggle}
              id="dropdown-toggle-quick-actions-subheader"
            />

            <Dropdown.Menu
              className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right"
              style={{ maxHeight: "300px", overflow: "scroll" }}
            >
              {/*begin::Navigation*/}
              <ul className="navi navi-hover">
                {roles &&
                  roles.map((institute, index) => (
                    <Fragment key={institute.instituteId}>
                      {index > 0 && (
                        <li className="navi-separator mb-3 opacity-70"></li>
                      )}
                      <li className="navi-header font-weight-bold py-5 bg-light-primary text-white">
                        <span className="font-size-lg">{institute.name}</span>
                      </li>
                      <li className="navi-separator mb-3 opacity-70"></li>
                      {institute.branchRoles &&
                        institute.branchRoles.map((branchRole) => (
                          <li
                            className="navi-item"
                            key={`${branchRole.branchId}-${branchRole.roleId}`}
                          >
                            <a
                              className="navi-link"
                              onClick={() => {
                                dispatch(
                                  authActions.requestSetInstituteBranch({
                                    instituteId: institute.instituteId,
                                    branchId: branchRole.branchId,
                                    branchName: branchRole.branch,
                                  })
                                );
                              }}
                            >
                              <span className="navi-text">
                                <span className="label label-xl label-inline bg-transparent">
                                  {branchRole.branch} ({branchRole.role})
                                </span>
                              </span>
                            </a>
                          </li>
                        ))}
                    </Fragment>
                  ))}
              </ul>
              {/*end::Navigation*/}
            </Dropdown.Menu>
          </Dropdown>
        </>
      )}

      <Dropdown
        className="dropdown dropdown-inline mr-10"
        drop="down"
        alignRight
      >
        <Dropdown.Toggle
          className="btn btn-clean btn-hover-light-primary btn-sm btn-icon cursor-pointer"
          variant="transparent"
          id="dropdown-toggle-top-user-profile"
          as={SwitchProfileDropdownToggle}
        >
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <ul className="navi navi-hover py-5">
            <li className="navi-item">
              <a href="/logout" className="navi-link">
                <span className="navi-icon">
                  <i className="flaticon-logout"></i>
                </span>
                <span className="navi-text">Logout</span>
              </a>
            </li>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
