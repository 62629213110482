export const Default = "Default";
export const Custom = "Custom";
export const userRoles = {
  superAdmin: "Super Admin",
};
export const ALL_FILE_FORMATS = ".jpg,.jpeg,.png,.gif,.txt,.pdf,.doc,.docx";
export const IMAGE_FORMATS = ".jpg,.jpeg,.png,.gif,.txt,.pdf,.doc,.docx";
export const DOCUMENT_FORMATS = ".jpg,.jpeg,.png,.gif,.txt,.pdf,.doc,.docx";
export const Genders = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Others", value: "other" },
];
export const Categories = [
  { label: "Gen", value: "gen" },
  { label: "OBC", value: "obc" },
  { label: "ST", value: "st" },
  { label: "SC", value: "sc" },
  { label: "Others", value: "others" },
];
export const Religions = [
  { label: "Hindu", value: "hindu" },
  { label: "Muslim", value: "muslim" },
  { label: "Sikh", value: "sikh" },
  { label: "Jain", value: "jain" },
  { label: "Buddhist", value: "buddhist" },
  { label: "Parsi", value: "parsi" },
  { label: "Christian", value: "Christian" },
];

export const Medium = [
  { label: "English", value: "English" },
  { label: "Hindi", value: "Hindi" },
];
export const Gender = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Others", value: "other" },
];
export const MOBILE_NUMBER_REGEX = "^[6-9][0-9]{9}$";
export const ADHAR_NUMBER_REGEX = "^[2-9]{1}[0-9]{11}$";
export const Category = [
  { label: "Gen", value: "gen" },
  { label: "OBC", value: "obc" },
  { label: "ST", value: "st" },
  { label: "SC", value: "sc" },
  { label: "Others", value: "others" },
];
export const Religion = [
  { label: "Hindu", value: "hindu" },
  { label: "Muslim", value: "muslim" },
  { label: "Sikh", value: "sikh" },
  { label: "Jain", value: "jain" },
  { label: "Buddhist", value: "buddhist" },
  { label: "Parsi", value: "parsi" },
  { label: "Christian", value: "Christian" },
];
export const associatedWith = [
    { label: "Father", value: "Father" },
    { label: "Mother", value: "Mother" },
  ];
  
  export const feeComponent = [
    { label: "Administration Fee", value: "Administration Fee" },
    { label: "Addmission Fee", value: "Addmission Fee" },
    { label: "Books And Note Books", value: "Books And Note Books" },
    { label: "Caution Money", value: "Caution Money" },
    { label: "Computer Admission Fee", value: "Computer Admission Fee" },
    { label: "Computer Fee (Monthly)", value: "Computer Fee (Monthly)" },
  ];
  
  export const otherFee = [
    { label: "Late Fee", value: "lateFee" },
    { label: "Cheque Bouncer", value: "chequeBounce" },
    { label: "Conveyance Fee", value: "conveyanceFee" },
  ];
  
  export const term = [
    { label: "Term-1", value: "term-1" },
    { label: "Term-2", value: "term-2" },
  ];
  

export const UIActions = {
  ADD_STUDENT_DETAILS: "Add Student Details",
  EDIT_STUDENT_DETAILS: "Edit Student Details",
  LIST_STUDENT_DETAILS: "List Student Details",
  VIEW_STUDENT_DETAILS: "View Student Details",
  DELETE_STUDENT_DETAILS: "Delete Student Details",
  CLONE_STUDENT_DETAILS: "Clone Student Details",
  IMPORT_STUDENT_DETAILS: "Import Student Details",
  EXPORT_STUDENT_DETAILS: "Export Student Details",
  PRINT_STUDENT_DETAILS: "Print Student Details",
  DOWNLOAD_STUDENT_DETAILS: "Download Student Details",
  MANAGE_STUDENT_DETAILS: "Manage Student Details",
  ADD_ADDRESS: "Add Address",
  EDIT_ADDRESS: "Edit Address",
  LIST_ADDRESS: "List Address",
  VIEW_ADDRESS: "View Address",
  DELETE_ADDRESS: "Delete Address",
  CLONE_ADDRESS: "Clone Address",
  IMPORT_ADDRESS: "Import Address",
  EXPORT_ADDRESS: "Export Address",
  PRINT_ADDRESS: "Print Address",
  DOWNLOAD_ADDRESS: "Download Address",
  MANAGE_ADDRESS: "Manage Address",
  ADD_EXAM_SCHEDULE: "Add Exam Schedule",
  EDIT_EXAM_SCHEDULE: "Edit Exam Schedule",
  LIST_EXAM_SCHEDULE: "List Exam Schedule",
  VIEW_EXAM_SCHEDULE: "View Exam Schedule",
  DELETE_EXAM_SCHEDULE: "Delete Exam Schedule",
  CLONE_EXAM_SCHEDULE: "Clone Exam Schedule",
  IMPORT_EXAM_SCHEDULE: "Import Exam Schedule",
  EXPORT_EXAM_SCHEDULE: "Export Exam Schedule",
  PRINT_EXAM_SCHEDULE: "Print Exam Schedule",
  DOWNLOAD_EXAM_SCHEDULE: "Download Exam Schedule",
  MANAGE_EXAM_SCHEDULE: "Manage Exam Schedule",
  ADD_PAYMENT_MODE: "Add Payment Mode",
  EDIT_PAYMENT_MODE: "Edit Payment Mode",
  LIST_PAYMENT_MODE: "List Payment Mode",
  VIEW_PAYMENT_MODE: "View Payment Mode",
  DELETE_PAYMENT_MODE: "Delete Payment Mode",
  CLONE_PAYMENT_MODE: "Clone Payment Mode",
  IMPORT_PAYMENT_MODE: "Import Payment Mode",
  EXPORT_PAYMENT_MODE: "Export Payment Mode",
  PRINT_PAYMENT_MODE: "Print Payment Mode",
  DOWNLOAD_PAYMENT_MODE: "Download Payment Mode",
  MANAGE_PAYMENT_MODE: "Manage Payment Mode",
  ADD_TRANSFER_STUDENT: "Add Transfer Student",
  EDIT_TRANSFER_STUDENT: "Edit Transfer Student",
  LIST_TRANSFER_STUDENT: "List Transfer Student",
  VIEW_TRANSFER_STUDENT: "View Transfer Student",
  DELETE_TRANSFER_STUDENT: "Delete Transfer Student",
  CLONE_TRANSFER_STUDENT: "Clone Transfer Student",
  IMPORT_TRANSFER_STUDENT: "Import Transfer Student",
  EXPORT_TRANSFER_STUDENT: "Export Transfer Student",
  PRINT_TRANSFER_STUDENT: "Print Transfer Student",
  DOWNLOAD_TRANSFER_STUDENT: "Download Transfer Student",
  MANAGE_TRANSFER_STUDENT: "Manage Transfer Student",
  ADD_TRANSFER_CERTIFICATE: "Add Transfer Certificate",
  EDIT_TRANSFER_CERTIFICATE: "Edit Transfer Certificate",
  LIST_TRANSFER_CERTIFICATE: "List Transfer Certificate",
  VIEW_TRANSFER_CERTIFICATE: "View Transfer Certificate",
  DELETE_TRANSFER_CERTIFICATE: "Delete Transfer Certificate",
  CLONE_TRANSFER_CERTIFICATE: "Clone Transfer Certificate",
  IMPORT_TRANSFER_CERTIFICATE: "Import Transfer Certificate",
  EXPORT_TRANSFER_CERTIFICATE: "Export Transfer Certificate",
  PRINT_TRANSFER_CERTIFICATE: "Print Transfer Certificate",
  DOWNLOAD_TRANSFER_CERTIFICATE: "Download Transfer Certificate",
  MANAGE_TRANSFER_CERTIFICATE: "Manage Transfer Certificate",
  ADD_INCOME_TAX_CERTIFICATE: "Add Income Tax Certificate",
  EDIT_INCOME_TAX_CERTIFICATE: "Edit Income Tax Certificate",
  LIST_INCOME_TAX_CERTIFICATE: "List Income Tax Certificate",
  VIEW_INCOME_TAX_CERTIFICATE: "View Income Tax Certificate",
  DELETE_INCOME_TAX_CERTIFICATE: "Delete Income Tax Certificate",
  CLONE_INCOME_TAX_CERTIFICATE: "Clone Income Tax Certificate",
  IMPORT_INCOME_TAX_CERTIFICATE: "Import Income Tax Certificate",
  EXPORT_INCOME_TAX_CERTIFICATE: "Export Income Tax Certificate",
  PRINT_INCOME_TAX_CERTIFICATE: "Print Income Tax Certificate",
  DOWNLOAD_INCOME_TAX_CERTIFICATE: "Download Income Tax Certificate",
  MANAGE_INCOME_TAX_CERTIFICATE: "Manage Income Tax Certificate",
  ADD_STUDENT_HEALTH_CARD: "Add Student Health Card",
  EDIT_STUDENT_HEALTH_CARD: "Edit Student Health Card",
  LIST_STUDENT_HEALTH_CARD: "List Student Health Card",
  VIEW_STUDENT_HEALTH_CARD: "View Student Health Card",
  DELETE_STUDENT_HEALTH_CARD: "Delete Student Health Card",
  CLONE_STUDENT_HEALTH_CARD: "Clone Student Health Card",
  IMPORT_STUDENT_HEALTH_CARD: "Import Student Health Card",
  EXPORT_STUDENT_HEALTH_CARD: "Export Student Health Card",
  PRINT_STUDENT_HEALTH_CARD: "Print Student Health Card",
  DOWNLOAD_STUDENT_HEALTH_CARD: "Download Student Health Card",
  MANAGE_STUDENT_HEALTH_CARD: "Manage Student Health Card",
  ADD_CHARACTER_CERTIFICATE: "Add Character Certificate",
  EDIT_CHARACTER_CERTIFICATE: "Edit Character Certificate",
  LIST_CHARACTER_CERTIFICATE: "List Character Certificate",
  VIEW_CHARACTER_CERTIFICATE: "View Character Certificate",
  DELETE_CHARACTER_CERTIFICATE: "Delete Character Certificate",
  CLONE_CHARACTER_CERTIFICATE: "Clone Character Certificate",
  IMPORT_CHARACTER_CERTIFICATE: "Import Character Certificate",
  EXPORT_CHARACTER_CERTIFICATE: "Export Character Certificate",
  PRINT_CHARACTER_CERTIFICATE: "Print Character Certificate",
  DOWNLOAD_CHARACTER_CERTIFICATE: "Download Character Certificate",
  MANAGE_CHARACTER_CERTIFICATE: "Manage Character Certificate",
  ADD_COMPALINT_LETTER: "Add Compalint Letter",
  EDIT_COMPALINT_LETTER: "Edit Compalint Letter",
  LIST_COMPALINT_LETTER: "List Compalint Letter",
  VIEW_COMPALINT_LETTER: "View Compalint Letter",
  DELETE_COMPALINT_LETTER: "Delete Compalint Letter",
  CLONE_COMPALINT_LETTER: "Clone Compalint Letter",
  IMPORT_COMPALINT_LETTER: "Import Compalint Letter",
  EXPORT_COMPALINT_LETTER: "Export Compalint Letter",
  PRINT_COMPALINT_LETTER: "Print Compalint Letter",
  DOWNLOAD_COMPALINT_LETTER: "Download Compalint Letter",
  MANAGE_COMPALINT_LETTER: "Manage Compalint Letter",
  ADD_APPRETIATION_CERTIFICATE: "Add Appretiation Certificate",
  EDIT_APPRETIATION_CERTIFICATE: "Edit Appretiation Certificate",
  LIST_APPRETIATION_CERTIFICATE: "List Appretiation Certificate",
  VIEW_APPRETIATION_CERTIFICATE: "View Appretiation Certificate",
  DELETE_APPRETIATION_CERTIFICATE: "Delete Appretiation Certificate",
  CLONE_APPRETIATION_CERTIFICATE: "Clone Appretiation Certificate",
  IMPORT_APPRETIATION_CERTIFICATE: "Import Appretiation Certificate",
  EXPORT_APPRETIATION_CERTIFICATE: "Export Appretiation Certificate",
  PRINT_APPRETIATION_CERTIFICATE: "Print Appretiation Certificate",
  DOWNLOAD_APPRETIATION_CERTIFICATE: "Download Appretiation Certificate",
  MANAGE_APPRETIATION_CERTIFICATE: "Manage Appretiation Certificate",
  ADD_PERMISSION_CERTIFICATE: "Add Permission Certificate",
  EDIT_PERMISSION_CERTIFICATE: "Edit Permission Certificate",
  LIST_PERMISSION_CERTIFICATE: "List Permission Certificate",
  VIEW_PERMISSION_CERTIFICATE: "View Permission Certificate",
  DELETE_PERMISSION_CERTIFICATE: "Delete Permission Certificate",
  CLONE_PERMISSION_CERTIFICATE: "Clone Permission Certificate",
  IMPORT_PERMISSION_CERTIFICATE: "Import Permission Certificate",
  EXPORT_PERMISSION_CERTIFICATE: "Export Permission Certificate",
  PRINT_PERMISSION_CERTIFICATE: "Print Permission Certificate",
  DOWNLOAD_PERMISSION_CERTIFICATE: "Download Permission Certificate",
  MANAGE_PERMISSION_CERTIFICATE: "Manage Permission Certificate",
  ADD_STUDENT_IMPORT: "Add Student Import",
  EDIT_STUDENT_IMPORT: "Edit Student Import",
  LIST_STUDENT_IMPORT: "List Student Import",
  VIEW_STUDENT_IMPORT: "View Student Import",
  DELETE_STUDENT_IMPORT: "Delete Student Import",
  CLONE_STUDENT_IMPORT: "Clone Student Import",
  IMPORT_STUDENT_IMPORT: "Import Student Import",
  EXPORT_STUDENT_IMPORT: "Export Student Import",
  PRINT_STUDENT_IMPORT: "Print Student Import",
  DOWNLOAD_STUDENT_IMPORT: "Download Student Import",
  MANAGE_STUDENT_IMPORT: "Manage Student Import",
  ADD_BOARD_REGISTRATION_NUMBER: "Add Board Registration Number",
  EDIT_BOARD_REGISTRATION_NUMBER: "Edit Board Registration Number",
  LIST_BOARD_REGISTRATION_NUMBER: "List Board Registration Number",
  VIEW_BOARD_REGISTRATION_NUMBER: "View Board Registration Number",
  DELETE_BOARD_REGISTRATION_NUMBER: "Delete Board Registration Number",
  CLONE_BOARD_REGISTRATION_NUMBER: "Clone Board Registration Number",
  IMPORT_BOARD_REGISTRATION_NUMBER: "Import Board Registration Number",
  EXPORT_BOARD_REGISTRATION_NUMBER: "Export Board Registration Number",
  PRINT_BOARD_REGISTRATION_NUMBER: "Print Board Registration Number",
  DOWNLOAD_BOARD_REGISTRATION_NUMBER: "Download Board Registration Number",
  MANAGE_BOARD_REGISTRATION_NUMBER: "Manage Board Registration Number",
  ADD_FEE_DETAILS: "Add Fee Details",
  EDIT_FEE_DETAILS: "Edit Fee Details",
  LIST_FEE_DETAILS: "List Fee Details",
  VIEW_FEE_DETAILS: "View Fee Details",
  DELETE_FEE_DETAILS: "Delete Fee Details",
  CLONE_FEE_DETAILS: "Clone Fee Details",
  IMPORT_FEE_DETAILS: "Import Fee Details",
  EXPORT_FEE_DETAILS: "Export Fee Details",
  PRINT_FEE_DETAILS: "Print Fee Details",
  DOWNLOAD_FEE_DETAILS: "Download Fee Details",
  MANAGE_FEE_DETAILS: "Manage Fee Details",
  ADD_FEE_RULE: "Add Fee Rule",
  EDIT_FEE_RULE: "Edit Fee Rule",
  LIST_FEE_RULE: "List Fee Rule",
  VIEW_FEE_RULE: "View Fee Rule",
  DELETE_FEE_RULE: "Delete Fee Rule",
  CLONE_FEE_RULE: "Clone Fee Rule",
  IMPORT_FEE_RULE: "Import Fee Rule",
  EXPORT_FEE_RULE: "Export Fee Rule",
  PRINT_FEE_RULE: "Print Fee Rule",
  DOWNLOAD_FEE_RULE: "Download Fee Rule",
  MANAGE_FEE_RULE: "Manage Fee Rule",
  ADD_PROMOTE_STUDENT: "Add Promote Student",
  EDIT_PROMOTE_STUDENT: "Edit Promote Student",
  LIST_PROMOTE_STUDENT: "List Promote Student",
  VIEW_PROMOTE_STUDENT: "View Promote Student",
  DELETE_PROMOTE_STUDENT: "Delete Promote Student",
  CLONE_PROMOTE_STUDENT: "Clone Promote Student",
  IMPORT_PROMOTE_STUDENT: "Import Promote Student",
  EXPORT_PROMOTE_STUDENT: "Export Promote Student",
  PRINT_PROMOTE_STUDENT: "Print Promote Student",
  DOWNLOAD_PROMOTE_STUDENT: "Download Promote Student",
  MANAGE_PROMOTE_STUDENT: "Manage Promote Student",
  ADD_TEMPERATURE_LOG: "Add Temperature Log",
  EDIT_TEMPERATURE_LOG: "Edit Temperature Log",
  LIST_TEMPERATURE_LOG: "List Temperature Log",
  VIEW_TEMPERATURE_LOG: "View Temperature Log",
  DELETE_TEMPERATURE_LOG: "Delete Temperature Log",
  CLONE_TEMPERATURE_LOG: "Clone Temperature Log",
  IMPORT_TEMPERATURE_LOG: "Import Temperature Log",
  EXPORT_TEMPERATURE_LOG: "Export Temperature Log",
  PRINT_TEMPERATURE_LOG: "Print Temperature Log",
  DOWNLOAD_TEMPERATURE_LOG: "Download Temperature Log",
  MANAGE_TEMPERATURE_LOG: "Manage Temperature Log",
  ADD_FATHER_DETAILS: "Add Father Details",
  EDIT_FATHER_DETAILS: "Edit Father Details",
  LIST_FATHER_DETAILS: "List Father Details",
  VIEW_FATHER_DETAILS: "View Father Details",
  DELETE_FATHER_DETAILS: "Delete Father Details",
  CLONE_FATHER_DETAILS: "Clone Father Details",
  IMPORT_FATHER_DETAILS: "Import Father Details",
  EXPORT_FATHER_DETAILS: "Export Father Details",
  PRINT_FATHER_DETAILS: "Print Father Details",
  DOWNLOAD_FATHER_DETAILS: "Download Father Details",
  MANAGE_FATHER_DETAILS: "Manage Father Details",
  ADD_MOTHER_DETAILS: "Add Mother Details",
  EDIT_MOTHER_DETAILS: "Edit Mother Details",
  LIST_MOTHER_DETAILS: "List Mother Details",
  VIEW_MOTHER_DETAILS: "View Mother Details",
  DELETE_MOTHER_DETAILS: "Delete Mother Details",
  CLONE_MOTHER_DETAILS: "Clone Mother Details",
  IMPORT_MOTHER_DETAILS: "Import Mother Details",
  EXPORT_MOTHER_DETAILS: "Export Mother Details",
  PRINT_MOTHER_DETAILS: "Print Mother Details",
  DOWNLOAD_MOTHER_DETAILS: "Download Mother Details",
  MANAGE_MOTHER_DETAILS: "Manage Mother Details",
  ADD_GUARDIAN_DETAILS: "Add Guardian Details",
  EDIT_GUARDIAN_DETAILS: "Edit Guardian Details",
  LIST_GUARDIAN_DETAILS: "List Guardian Details",
  VIEW_GUARDIAN_DETAILS: "View Guardian Details",
  DELETE_GUARDIAN_DETAILS: "Delete Guardian Details",
  CLONE_GUARDIAN_DETAILS: "Clone Guardian Details",
  IMPORT_GUARDIAN_DETAILS: "Import Guardian Details",
  EXPORT_GUARDIAN_DETAILS: "Export Guardian Details",
  PRINT_GUARDIAN_DETAILS: "Print Guardian Details",
  DOWNLOAD_GUARDIAN_DETAILS: "Download Guardian Details",
  MANAGE_GUARDIAN_DETAILS: "Manage Guardian Details",
};
