import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_SSO_API_URL}login`;
export const VALIDATE_TOKEN = `${process.env.REACT_APP_SSO_API_URL}validate-token`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_SSO_API_URL}user/me`;
export const USER_PERMISSIONS_URL = `${process.env.REACT_APP_AUTHORIZATION_API_URL}user-permissions`;

export async function login(email, password) {
  const response = await axios.post(LOGIN_URL, { email, password });

  const redirectUrl = response.data.redirectUrl;
  const referralToken = redirectUrl.substring(
    redirectUrl.indexOf("=") + 1,
    redirectUrl.indexOf("&")
  );

  const tokens = await axios.post(VALIDATE_TOKEN, { referralToken });

  return tokens;
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function getUserPermissions() {
  return axios.post(USER_PERMISSIONS_URL, {
    module: process.env.REACT_APP_MODULE_NAME,
  });
}

export function getInstitutes() {
  return axios.get(`${process.env.REACT_APP_SSO_API_URL}get-institutes`);
}

export function getBranches() {
  return axios.get(`${process.env.REACT_APP_SSO_API_URL}get-branches`);
}

export function setInstituteBranch(data) {
  return axios.post(
    `${process.env.REACT_APP_SSO_API_URL}set-institute-branch`,
    data
  );
}

export async function validateReferralToken(referralToken) {
  const tokens = await axios.post(VALIDATE_TOKEN, { referralToken });

  return tokens;
}
