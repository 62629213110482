import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  academicSessions: null,
  classNames: null,
  sections: null,
  subjects: null,
  error: null,
  countries: undefined,
  states: null,
  cities: null,
};
export const callTypes = {
    list: "list",
  action: "action",
};

export const mastersSlice = createSlice({
  name: "masters",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {state.error = `${action.type}: ${action.payload.error}`;
        state.listLoading = false;
      },
      startCall: (state, action) => {
        state.error = null;
        state.listLoading = true;
      },

    academicSessionsFetched: (state, action) => {
      const { searchItems } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.academicSessions = searchItems;
    },

    classNamesFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.classNames = action.payload.data;
    },

    sectionsFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.sections = action.payload.data;
    },

    subjectsFetched: (state, action) => {
      const { searchItems } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.subjects = searchItems;
    },
    fetchCountries: (state, action) => {
      const { countries } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.countries = countries;
    },
    fetchStates: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.states = action.payload.states
    },
    fetchCity: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { cities } = action.payload;
      state.cities = cities;
    },

  },
});
