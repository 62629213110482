import React, { Suspense, lazy } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";

const StudentDetailsPage = lazy(() =>
  import("./modules/StudentDetails/pages/index")
);
const StudentIdCard = lazy(() => import("./modules/StudentIdCard/pages/index"));

const StudentSiblingList = lazy(() =>
  import("./modules/StudentSiblingList/pages/index")
);

const StudentUpdateRequest = lazy(() =>
  import("./modules/StudentUpdateRequest/pages/index")
);


const MassUpdate = lazy(() => import("./modules/MassUpdate/pages/index"));

const OnlineRegisteredPage = lazy(() =>
  import("./modules/OnlineRegistered/pages/index")
);
const OnlineTransactionPage = lazy(() =>
  import("./modules/OnlineTransaction/pages/index")
);
const AddressesPage = lazy(() => import("./modules/Addresses/pages/index"));
const ExamSchedulesPage = lazy(() =>
  import("./modules/ExamSchedules/pages/index")
);
const PaymentModesPage = lazy(() =>
  import("./modules/PaymentModes/pages/index")
);
const TransferStudentsPage = lazy(() =>
  import("./modules/TransferStudents/pages/index")
);
const TransferToPage = lazy(() => import("./modules/TransferTo/pages/index"));

const TransferFromPage = lazy(() =>
  import("./modules/TransferFrom/pages/index")
);

const TransferCertificatesPage = lazy(() =>
  import("./modules/TransferCertificates/pages/index")
);
const IncomeTaxCertificatesPage = lazy(() =>
  import("./modules/IncomeTaxCertificates/pages/index")
);
const StudentHealthCardsPage = lazy(() =>
  import("./modules/StudentHealthCards/pages/index")
);
const CharacterCertificatesPage = lazy(() =>
  import("./modules/CharacterCertificates/pages/index")
);
const CompalintLettersPage = lazy(() =>
  import("./modules/CompalintLetters/pages/index")
);
const AppretiationCertificatesPage = lazy(() =>
  import("./modules/AppretiationCertificates/pages/index")
);
const PermissionCertificatesPage = lazy(() =>
  import("./modules/PermissionCertificates/pages/index")
);
const StudentImportsPage = lazy(() =>
  import("./modules/StudentImports/pages/index")
);
const BoardRegistrationNumbersPage = lazy(() =>
  import("./modules/BoardRegistrationNumbers/pages/index")
);
const FeeDetailsPage = lazy(() => import("./modules/FeeDetails/pages/index"));
const FeeRulesPage = lazy(() => import("./modules/FeeRules/pages/index"));
const PromoteStudentsPage = lazy(() =>
  import("./modules/PromoteStudents/pages/index")
);
const TemperatureLogsPage = lazy(() =>
  import("./modules/TemperatureLogs/pages/index")
);
const FatherDetailsPage = lazy(() =>
  import("./modules/FatherDetails/pages/index")
);
const MotherDetailsPage = lazy(() =>
  import("./modules/MotherDetails/pages/index")
);
const MedicalDetailsPage = lazy(() =>
  import("./modules/MedicalDetails/pages/index")
);
const GuardianDetailsPage = lazy(() =>
  import("./modules/GuardianDetails/pages/index")
);
const StudentStatusPage = lazy(() =>
  import("./modules/StudentStatus/pages/index")
);
const StrengthComparisionPage = lazy(() =>
  import("./modules/StrengthComparision/pages/index")
);
const StudentStatusLog = lazy(() =>
  import("./modules/StudentStatusLog/pages/index")
);
const StudentOptionalSubject = lazy(() =>
  import("./modules/StudentOptionalSubject/pages/index")
);
const LoginDetailRequest = lazy(() =>
import("./modules/LoginDetailRequest/pages/index")
);
const LoginStatusReport = lazy(() =>
import("./modules/LoginStatusReport/pages/index")
);
const VaccineCovidReport = lazy(() =>
import("./modules/VaccineAndReport/pages/index")
);
const TemperatureLogReport = lazy(() =>
import("./modules/TemperatureLogReport/pages/index")
);
const StudentLoginReport = lazy(() =>
import("./modules/StudentLoginReport/pages/index")
);

const LeftStudent = lazy(() => import("./modules/LeftStudent/pages/index"));
const TcIssuedPage = lazy(() => import("./modules/TcIssued/pages/index"));
const DailyPage = lazy(() => import("./modules/Daily/pages/index"));
export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />

        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/student-details" component={StudentDetailsPage} />
        <ContentRoute path="/student-id-card" component={StudentIdCard} />
        <ContentRoute
          path="/student-sibling-list"
          component={StudentSiblingList}
        />
        <ContentRoute
          path="/student-update-request"
          component={StudentUpdateRequest}
        />
        <ContentRoute
          path="/login-detail-request"
          component={LoginDetailRequest}
        />
        <ContentRoute path="/mass-update" component={MassUpdate} />
        <ContentRoute path="/student-status" component={StudentStatusPage} />
        <ContentRoute
          path="/strength-comparision"
          component={StrengthComparisionPage}
        />
        <ContentRoute path="/tc-issued" component={TcIssuedPage} />
        <ContentRoute
          path="/online-registered"
          component={OnlineRegisteredPage}
        />
        <ContentRoute
          path="/online-transaction"
          component={OnlineTransactionPage}
        />
        <ContentRoute path="/addresses" component={AddressesPage} />
        <ContentRoute path="/exam-schedules" component={ExamSchedulesPage} />
        <ContentRoute path="/payment-modes" component={PaymentModesPage} />
        <ContentRoute
          path="/transfer-students"
          component={TransferStudentsPage}
        />

        <ContentRoute path="/transfer-to" component={TransferToPage} />
        <ContentRoute path="/transfer-from" component={TransferFromPage} />

        <ContentRoute
          path="/transfer-certificates"
          component={TransferCertificatesPage}
        />
        <ContentRoute
          path="/income-tax-certificates"
          component={IncomeTaxCertificatesPage}
        />
        <ContentRoute
          path="/student-health-cards"
          component={StudentHealthCardsPage}
        />
        <ContentRoute
          path="/character-certificates"
          component={CharacterCertificatesPage}
        />
        <ContentRoute
          path="/compalint-letters"
          component={CompalintLettersPage}
        />
        <ContentRoute
          path="/appretiation-certificates"
          component={AppretiationCertificatesPage}
        />
        <ContentRoute
          path="/permission-certificates"
          component={PermissionCertificatesPage}
        />
        <ContentRoute path="/student-imports" component={StudentImportsPage} />
        <ContentRoute
          path="/board-registration-numbers"
          component={BoardRegistrationNumbersPage}
        />
        <ContentRoute path="/fee-details" component={FeeDetailsPage} />
        <ContentRoute path="/fee-rules" component={FeeRulesPage} />
        <ContentRoute
          path="/promote-students"
          component={PromoteStudentsPage}
        />
        <ContentRoute
          path="/temperature-logs"
          component={TemperatureLogsPage}
        />
        <ContentRoute path="/father-details" component={FatherDetailsPage} />
        <ContentRoute path="/mother-details" component={MotherDetailsPage} />
        <ContentRoute path="/medical-details" component={MedicalDetailsPage} />
        <ContentRoute
          path="/guardian-details"
          component={GuardianDetailsPage}
        />

        <ContentRoute path="/student-status-log" component={StudentStatusLog} />
        <ContentRoute
          path="/student-optional-subject"
          component={StudentOptionalSubject}
        />
        <ContentRoute
          path="/login-status-report"
          component={LoginStatusReport}
        />
        <ContentRoute
          path="/vaccine-report"
          component={VaccineCovidReport}
        />
           <ContentRoute
          path="/temperature-log-report"
          component={TemperatureLogReport}
        />
          <ContentRoute
          path="/student-login-report"
          component={StudentLoginReport}
        />
        <ContentRoute
          path="/daily"
          component={DailyPage}
        />
        <ContentRoute path="/left-student" component={LeftStudent} /> 
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
