import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { studentDetailsSlice } from "../app/modules/StudentDetails/_redux/slice";
import { addressesSlice } from "../app/modules/Addresses/_redux/slice";
import { examSchedulesSlice } from "../app/modules/ExamSchedules/_redux/slice";
import { paymentModesSlice } from "../app/modules/PaymentModes/_redux/slice";
import { transferStudentsSlice } from "../app/modules/TransferStudents/_redux/slice";
import { transferCertificatesSlice } from "../app/modules/TransferCertificates/_redux/slice";
import { incomeTaxCertificatesSlice } from "../app/modules/IncomeTaxCertificates/_redux/slice";
import { studentHealthCardsSlice } from "../app/modules/StudentHealthCards/_redux/slice";
import { characterCertificatesSlice } from "../app/modules/CharacterCertificates/_redux/slice";
import { compalintLettersSlice } from "../app/modules/CompalintLetters/_redux/slice";
import { appretiationCertificatesSlice } from "../app/modules/AppretiationCertificates/_redux/slice";
import { permissionCertificatesSlice } from "../app/modules/PermissionCertificates/_redux/slice";
import { studentImportsSlice } from "../app/modules/StudentImports/_redux/slice";
import { boardRegistrationNumbersSlice } from "../app/modules/BoardRegistrationNumbers/_redux/slice";
import { feeDetailsSlice } from "../app/modules/FeeDetails/_redux/slice";
import { feeRulesSlice } from "../app/modules/FeeRules/_redux/slice";
import { promoteStudentsSlice } from "../app/modules/PromoteStudents/_redux/slice";
import { temperatureLogsSlice } from "../app/modules/TemperatureLogs/_redux/slice";
import { fatherDetailsSlice } from "../app/modules/FatherDetails/_redux/slice";
import { motherDetailsSlice } from "../app/modules/MotherDetails/_redux/slice";
import { guardianDetailsSlice } from "../app/modules/GuardianDetails/_redux/slice";
import { mastersSlice } from "./_components/slice";
import {studentOptionalSubjectSlice} from "../app/modules/StudentOptionalSubject/_redux/slice";
import {studentStatusLogSlice} from "../app/modules/StudentStatusLog/_redux/slice";
import {leftStudentSlice} from "../app/modules/LeftStudent/_redux/slice"
import {loginStatusReportSlice} from "../app/modules/LoginStatusReport/_redux/slice"
import { vaccineAndReportSlice } from "../app/modules/VaccineAndReport/_redux/slice";
import { temperatureLogReportSlice } from "../app/modules/TemperatureLogReport/_redux/slice";
import {StudentLoginReportSlice} from "../app/modules/StudentLoginReport/_redux/slice"
import {studentNewSiblingListSlice} from "../app/modules/StudentSiblingList/_redux/slice"
import { attendancelistingsSlice } from "../app/modules/Daily/_redux/slice";
import { loginDetailRequestSlice } from "../app/modules/LoginDetailRequest/_redux/slice";
import { massUpdateSlice} from "../app/modules/MassUpdate/_redux/slice";
import {studentUpdateRequestSlice} from "../app/modules/StudentUpdateRequest/_redux/slice";
import {tcIssuedSlice} from "../app/modules/TcIssued/_redux/slice";
import {onlineTransactionSlice} from "../app/modules/OnlineTransaction/_redux/slice";
import {studentImportSlice} from "../app/modules/StudentImport/_redux/slice";
import {studentStatusSlice} from "../app/modules/StudentStatus/_redux/slice";
import {strengthComparisionSlice} from "../app/modules/StrengthComparision/_redux/slice";
import {studentIdCardSlice} from "../app/modules/StudentIdCard/_redux/slice"
import { transferToSlice } from "../app/modules/TransferTo/_redux/slice";
import { transferFromSlice } from "../app/modules/TransferFrom/_redux/slice";
import { onlineRegisteredSlice } from "../app/modules/OnlineRegistered/_redux/slice";
import {feeCollectionSlice} from "../app/modules/StudentDetails/pages/StudentDetailsProfile/_components/FeeCollection/_redux/slice";
import {transferStudentSlice} from "../app/modules/StudentDetails/pages/StudentDetailsProfile/_components/TransferStudent/_redux/slice";
import { generateIdCardSlice } from "../app/modules/StudentDetails/pages/StudentDetailsProfile/_components/GenerateIdCard/_redux/slice";
import { generateTcSlice } from "../app/modules/StudentDetails/pages/StudentDetailsProfile/_components/GenerateTc/_redux/slice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  studentDetails: studentDetailsSlice.reducer,
  addresses: addressesSlice.reducer,
  examSchedules: examSchedulesSlice.reducer,
  paymentModes: paymentModesSlice.reducer,
  transferStudents: transferStudentsSlice.reducer,
  transferCertificates: transferCertificatesSlice.reducer,
  incomeTaxCertificates: incomeTaxCertificatesSlice.reducer,
  studentHealthCards: studentHealthCardsSlice.reducer,
  characterCertificates: characterCertificatesSlice.reducer,
  compalintLetters: compalintLettersSlice.reducer,
  appretiationCertificates: appretiationCertificatesSlice.reducer,
  permissionCertificates: permissionCertificatesSlice.reducer,
  studentImports: studentImportsSlice.reducer,
  boardRegistrationNumbers: boardRegistrationNumbersSlice.reducer,
  feeDetails: feeDetailsSlice.reducer,
  feeRules: feeRulesSlice.reducer,
  promoteStudents: promoteStudentsSlice.reducer,
  temperatureLogs: temperatureLogsSlice.reducer,
  fatherDetails: fatherDetailsSlice.reducer,
  motherDetails: motherDetailsSlice.reducer,
  guardianDetails: guardianDetailsSlice.reducer,
  masters: mastersSlice.reducer,
  studentOptionalSubject:studentOptionalSubjectSlice.reducer,
  leftStudent:leftStudentSlice.reducer,
  loginStatusReport:loginStatusReportSlice.reducer,
  vaccineAndReport:vaccineAndReportSlice.reducer,
  temperatureLogReport:temperatureLogReportSlice.reducer,
  StudentLoginReport:StudentLoginReportSlice.reducer,
  studentNewSiblingList:studentNewSiblingListSlice.reducer,
  attendancelisting: attendancelistingsSlice.reducer,
  loginDetailRequest: loginDetailRequestSlice.reducer,
  massUpdate: massUpdateSlice.reducer, 
  studentUpdateRequest: studentUpdateRequestSlice.reducer,
  tcIssued: tcIssuedSlice.reducer,
  transferFrom: transferFromSlice.reducer,
  transferTo: transferToSlice.reducer,
  onlineTransaction: onlineTransactionSlice.reducer,
  studentImport: studentImportSlice.reducer,
  studentStatus: studentStatusSlice.reducer,
  strengthComparision: strengthComparisionSlice.reducer,
  studentIdCard:studentIdCardSlice.reducer,
  transferTo:transferToSlice.reducer,
  transferFrom:transferFromSlice.reducer,
  onlineRegistered:onlineRegisteredSlice.reducer,
  feeCollection:feeCollectionSlice.reducer,
  transferStudent:transferStudentSlice.reducer,
  generateIdCard:generateIdCardSlice.reducer,
  generateTc:generateTcSlice.reducer,
  studentStatusLog:studentStatusLogSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
