import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import * as auth from "../_redux/authRedux";
import { validateReferralToken } from "../_redux/authCrud";
import { NotificationManager } from "../../../../_component/react-notifications";
// import {NotificationManager} from '../../../../_component/react-notifications';

function ValidateToken(props) {
  const [loading, setLoading] = useState(false);
  const [token] = useState(
    new URLSearchParams(useLocation().search).get("token")
  );

  useEffect(() => {
    enableLoading();

    localStorage.removeItem("authToken");
    localStorage.removeItem("StudentProfile");

    if (token) {
      validateReferralToken(token)
        .then(({ data: { accessToken, refreshToken, referralToken } }) => {
          disableLoading();

          props.login(accessToken, refreshToken, referralToken);
        })
        .catch(() => {
          NotificationManager.error(
            "User details not found",
            "",
            3000,
            null,
            null,
            ""
          );
        })
        .finally(() => {
          disableLoading();
        });
    } else {
      window.location.href = process.env.REACT_APP_ROUVR_URL;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.VALIDATE_TOKEN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Please wait while we are validating your credentials
        </p>
        {loading && <span className="ml-3 spinner spinner-white"></span>}
      </div>
      {/* end::Head */}
    </div>
  );
}

export default connect(null, auth.actions)(ValidateToken);
